.rdw-editor-main {
  overflow: auto;
  border: 1px solid rgba(34,36,38, 0.15);
  border-radius: 4px;
  min-height: 250px !important;
  padding-left: 10px;
  padding-right: 10px;
  width: inherit;

}
