/* @media (max-width: 1100px) {
  .menu-container{
    top: 0;
    width: 100%;
    height: 300px;
    z-index: 3;
    position: fixed;
    padding-top: 10px;
  }
  .main{
    margin-top: 300px;
    z-index: 2;
    overflow: scroll;
  }
}
@media (min-width: 1101px) {
  .menu-container{
    width: 300px;
    height: 100%;
    flex-basis: 300px;
    flex: initial;
    margin: 10px 20px 50px 10px;
    position: relative;
  }
  .main{
    margin-top: 0;
  }
} */
#step-1, #step-2, #step-3, #step-4, #step-5 {
  border-bottom: 1px solid grey;
  margin-top: 50px;
}
#step-6{
  margin-top: 50px;
  border-bottom: 1px solid grey;
}
#app-name, #app-name:hover, #app-name:active {
  text-decoration: none;
  background-color: #F7F7F7;
  outline: none;
  border-color: grey;
}
.infoBar {
  width: 100%;
  display: none;
  flex-wrap: no-wrap;
  background-color: #CBA84A;
  box-sizing: border-box;
  height: 55px;
  line-height: 55px;
  vertical-align: middle;
  padding-left: 50px;
  font-family: Nunito;
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.infoBarText {
  display: flex;
  flex-grow: 1;
  text-align: left
}

.infoBarCloseButton {
  width: 55px;
  flex-frow: 0;
  flex-shrink: 0;
  height: 55px;
  display: inline-flex;
  text-align: center;
  background-color: #A28024;
  justify-content: center;
  cursor: pointer

}
/*
.center {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
}
*/
.page-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* width: 100vw; */
  background-color: #F7F7F7;
  /* justify-content: space-around; */
  /* padding-top: 70px; */
  margin-top: 40px;
}

.main {
  line-height: 30px;
  font-size: 14px;
  display: flex;
  /* width: 80vw; */
  flex-basis: 400px;
  box-sizing: border-box;
  flex-grow: 1;
  justify-content: flex-end;
  flex-direction: column;
  margin-left: 70px;
  /* margin-right: 20px */
}
.menu-container{
      display: flex;
      justify-content: flex-start;
      background-color: #F0F0F0;
}
.sticky-menu{
    position: fixed;
    top: 10;
    min-width: 227px;
    left: 10;
    margin-left: 5px;
    z-index: 10;
    height: 300px;
}
.user-avatar {
  margin-top: 24px;
  border: 1px solid #DDDDDD;
  border-radius: 50%;
  background-color: #DBDBDB;
  width: 48px;
  height: 48px;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 48px 48px;
}

.top-header {
  width: 100%;
  height: 50px;
  text-align: left;
  padding-left: 50px;
  font-size: 20px;
  margin-top: 50px
}

.wizard-step-container {
  display: flex;
  width: 100%;
  margin-right: 100px;
}

.wizard-step {
  padding: 5px;
  /* margin-bottom: 50px;
  margin-top: 50px; */
  margin-left: 50px;
  padding-right: 50px;
  width: 100%;
}

.hr-line {
  opacity: 0.2;
  margin-right: 50px;
  height: 10;
  width: 100%;
}

#step-1, #step-2, #step-3, #step-4, #step-5, #step-6 {
  /* padding-top: 70px;
  margin-top: -70px; */
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 90px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  margin-left: -20px;
  /* height: 100%; */
}

/* General sidebar styles */
.bm-menu {
  background: #F0F0F0;
  /* border: 1px solid #373a47; */
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  height: 400px !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0);
}
