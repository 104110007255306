/* .loginForm {
  height: 100%;
  text-align: center;
} */

#forgotPasswordContainer {
  display: inline-block;
  padding-bottom: 8px;
  margin-bottom: 32px;
}

#forgotPasswordContainer > a {
  font-size: 15px;
}

#requestLink {
  color: #9A9A9A;
  font-size: 27px;
}
.form-buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-control-right {
  text-align: right;
  margin-bottom: 20px;
}
.formContainer {
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.form-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

@media (min-width: 720px) {

  h1.ui.header {
    font-size: 57px;
  }

  h2.ui.header {
    font-size: 40px;
  }
  .form-item{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    min-width: 200px;
  }
  .loginContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    justify-content: center/;
  }
  .loginForm {
    min-width: 500px;
  }
  /* .loginForm{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex: 1;
  } */
  .formControl{
    display: flex;
    justify-content: center;
  }
  .logo{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex: 1;
    margin: 50px;
  }
}

@media (max-width: 720px) {

  .loginContainer {
    /* margin-top: 120px; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
  }
  .loginForm{
    width: 80%;
  }
  .form-item{
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 200px;
  }
  .logo{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;
    margin: 50px;
  }
  /* #topLogo {
    margin-top: 50px;
    margin-bottom: 50px;
    display: inline-block;
  } */
  .formControl{
    display: flex;
    justify-content: center;
    flex: 1;
    width: inherit;
  }
}

@media (max-height: 632px) {
  #topLogo {
    display: none;
  }
}
