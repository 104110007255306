@media (max-width: 950px) {
  #largeScreenFilter{
    display: none;
  }
  #smallScreenFilter{
    padding-left: 50px;
  }
  #performerContainer{
    padding-bottom: 100px;
    margin-top: 50px;
  }
  #filterButtons{
    display: block;
  }
  .hideWhenMinimized {
    display: none;
  }
}
@media (min-width: 951px) {
  #smallScreenFilter{
    display: none;
  }
  #filterButtons{
    display: inline-block;
  }
  #performerContainer{
    padding-bottom: 100px;
    margin-top: 0px;
  }
  .hideWhenMinimized {
      display: inline-block;
    }
}
@media (max-width: 1119px) {
  #filterButtons{
    display: block;
    min-width: 200px;
  }
}

#largeScreenFilter, #smallScreenFilter{
  padding-top: 5px;
  position: relative;
}
