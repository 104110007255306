/*::placeholder {
	color: darkgrey !important;
}*/

#root {
  height: 100%;
}
.ui.form .disabled.field input{
	color: black !important;
  opacity: 0.5;
}

.fullHeight {
    height: 100%;
}
#innerPage {
  height: 100%;
  margin-right: 150px;
  overflow: scroll;
  border: 0;
}

#menuContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  /* border: 0; */
}

#resizingMenu {
  flex: 1;
  margin-top: 10px;
}
#innerSideMenu {
  margin-top: 55px !important;
  /* overflow: hidden; */
}

#content-container{
  padding-top: 59px;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
#pushable-container{
  margin-bottom: -55px; /* to prevent the left vertical menu from hiding under the header on scrolling*/
  height: 100%;
}
table.center {
  display: table;
}
.tablerow:hover {
  cursor: pointer;
  outline: 1px solid steelblue;
  -ms-transform: scaleY(1.1); /* IE 9 */
  -webkit-transform: scaleY(1.1); /* Safari 3-8 */
  transform: scaleY(1.1);
}

/* @media (max-width: 700px) {
  #nav-bar: {
    margin-bottom: 50px;
  }
} */


.cc-datetime-input {
	display: flex;
}
.ui.form .fields .cc-datetime-input > .field {
	margin: 0
}

.ui.form .fields .cc-datetime-input > .field:nth-child(1) {
	padding-right: 0;
	width: 9.5em;
}
.ui.form .fields .cc-datetime-input > .field:nth-child(2) {
	padding-left: 0;
	width: 6.5em;

}
.ui.cards>.card>.image.player-image {
	position: relative;
	background-color: #F7F7F7;
}

.ui.cards>.card>.image.player-image::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.ui.cards>.card>.image.player-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.date-input-wrapper {
  position: relative;
  width: 100%;
}

.date-input-wrapper input[type="date"]::-webkit-calendar-picker-indicator {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  opacity: 0;
  cursor: pointer;
}

.date-input-wrapper input[type="text"] {
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.28571429rem;
  padding: 0.67857143em 1em;
  font-family: inherit;
  font-size: 1em;
}

.date-input-wrapper input[type="text"]:focus {
  border-color: #85b7d9;
  background: #fff;
  box-shadow: 0 0 0 0 rgba(34,36,38,.35) inset;
}

.date-input-wrapper input[type="date"] {
  color: transparent;
  background: transparent;
  border: none;
}

/* DatePicker Semantic UI styling */
.semantic-ui-datepicker {
  width: 100%;
  min-width: 180px;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.28571429rem;
  padding: 0.67857143em 1em;
  font-family: inherit;
  font-size: 1em;
}

.semantic-ui-datepicker:focus {
  border-color: #85b7d9;
  background: #fff;
  box-shadow: 0 0 0 0 rgba(34,36,38,.35) inset;
}

/* Calendar popup styling */
.semantic-ui-calendar {
  font-family: inherit !important;
  font-size: 1em !important;
}

.semantic-ui-calendar .react-datepicker__header {
  background-color: #f9fafb;
  border-bottom: 1px solid rgba(34,36,38,.1);
}

.semantic-ui-calendar .react-datepicker__current-month {
  font-size: 1em;
  font-weight: bold;
}

.semantic-ui-calendar .react-datepicker__day--selected {
  background-color: #2185d0;
  color: white;
}

.semantic-ui-calendar .react-datepicker__day:hover {
  background-color: #f0f0f0;
}

.semantic-ui-calendar .react-datepicker__day--keyboard-selected {
  background-color: rgba(33,133,208,0.2);
}

.semantic-ui-calendar .react-datepicker__navigation {
  top: 1em;
}

.semantic-ui-calendar .react-datepicker__year-dropdown,
.semantic-ui-calendar .react-datepicker__month-dropdown {
  background-color: white;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: 0.28571429rem;
}

.semantic-ui-calendar .react-datepicker__year-option:hover,
.semantic-ui-calendar .react-datepicker__month-option:hover {
  background-color: #f0f0f0;
}
